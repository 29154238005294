var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tableAvater" }, [
    _c("div", { staticClass: "box", on: { click: _vm.infoCaback } }, [
      _vm.hasAvator
        ? _c("img", {
            staticClass: "img_box",
            attrs: {
              src:
                _vm.basePath +
                "/hrsaas-base/staff/getAvatar?onJobId=" +
                _vm.onJobId +
                "&width=" +
                28 +
                "&height=" +
                28,
            },
            on: { error: _vm.errFunc },
          })
        : _c("div", { staticClass: "img_name" }, [
            _vm._v(_vm._s(_vm.getAvaterName(_vm.avaterName))),
          ]),
      _vm.showTableName
        ? _c("div", { staticClass: "name", attrs: { title: _vm.tableName } }, [
            _vm._v(_vm._s(_vm.tableName)),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }