∑<template>
  <div class="tableAvater">
    <div class="box" @click="infoCaback">
      <img
        v-if="hasAvator"
        :src="`${basePath}/hrsaas-base/staff/getAvatar?onJobId=${onJobId}&width=${28}&height=${28}`"
        class="img_box"
        @error="errFunc"
      >
      <div v-else class="img_name">{{ getAvaterName(avaterName) }}</div>
      <div v-if="showTableName" class="name" :title="tableName">{{ tableName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableAvater',
  props: {
    tableName: {
      type: String,
      default: ''
    },
    onJobId: {
      type: [String, Number],
      default: null
    },
    staffId: {
      type: [String, Number],
      default: null
    },
    // 是否展示员工姓名
    showTableName: {
      type: Boolean,
      default: true
    },
    // true 跳转到社保信息
    isArchives: {
      type: Boolean,
      default: false
    },
    // 跳转来源类型
    statusType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hasAvator: true,
      basePath: process.env.VUE_APP_API_BASE_URL
    }
  },
  computed: {
    avaterName() {
      return this.tableName
    }
  },
  watch: {
    // tableName: {
    //   handler: function(val) {
    //     if (val) {
    //       const arr = val.split('')
    //       this.avaterName = arr[arr.length - 2] + arr[arr.length - 1]
    //     }
    //   },
    //   immediate: true
    // },
    tableId() {
      this.hasAvator = true
    },
    onJobId(newVal) {
      this.hasAvator = true
    }
  },
  methods: {
    getAvaterName(avaterName) {
      if (avaterName) {
        if (avaterName.includes('(已离职)') || avaterName.includes('（已离职）')) {
          const index = avaterName.indexOf('已离职')
          const avaterNames = avaterName.replace((avaterName.slice(index - 1, index + 7)), '')
          const avataUserName = avaterNames.length > 1 ? avaterNames.substr(-2, 2) : avaterNames.length === 1 ? avaterNames.substr(-1, 1) : ''
          return avataUserName
        }
        avaterName = avaterName.length > 1 ? avaterName.substr(-2, 2) : avaterName.length === 1 ? avaterName.substr(-1, 1) : ''
        return avaterName
      }
    },
    // 跳转个人信息页面
    infoCaback() {
      const url = this.isArchives ? '/socialSecurity/securityArchivesDetail' : '/staffIndex/userDetail'
      this.$router.push({
        path: url,
        query: {
          staffId: this.staffId,
          onJobId: this.onJobId,
          statusType: this.statusType,
          path: encodeURIComponent(this.$route.fullPath),
          isRouterBack: this.isRouterBack
        }
      })
    },
    errFunc() {
      this.hasAvator = false
    }
  }
}
</script>

<style lang="less" scoped>
.tableAvater{
  display: inline-block;
  vertical-align: middle;
  .box {
    display: flex;
    align-items: center;
    cursor: pointer;
    .img_box{
      flex-shrink: 0;
      width: 34px;
      height: 34px;
      background: @sc-primary-80;
      border-radius: 50%;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
      line-height: 34px;
    }
    .img_name {
      flex-shrink: 0;
      width: 34px;
      height: 34px;
      background: linear-gradient(180deg,  @sc-primary-60 0%, @sc-primary-100 100%);
      border-radius: 50%;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
      line-height: 34px;
    }
    .name {
      .font-size(14px);
      color: @sc-primary-80;
      margin-left: 6px;
      .multi-ellipsis(1);
      white-space: break-spaces;
    }
  }
}
</style>
